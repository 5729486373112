import React from "react"
import styled from '@emotion/styled'
import { PropTypes } from "prop-types"
import { mediaQuery } from "./shared-styles"
import borderHorizontal from '../images/borderHorizontal.svg';
import Img from "gatsby-image"

const Wrapper = styled('div')(props => ({
  position: 'relative',
  width: '100%',
  height: '350px',
  marginTop: props.marginTop? '20px' : '0',
  clear: 'both',
  [mediaQuery.medium]: ({
    height: '750px',
  }),
  [mediaQuery.large]: ({
    height: '1000px',
  })
}));

const Image = styled(Img)({
  width: '100%',
  height: 'calc(350px - 40px)',
  [mediaQuery.medium]: ({
    width: 'calc(100% - 40px)',
    height: 'calc(750px - 40px)',
    margin: '0 0 0 20px',
  }),
  [mediaQuery.large]: ({
    height: 'calc(1000px - 40px)',
  })
});

const BorderTop = styled('div')({
  width: 'calc(100% + 40px)',
  height: '20px',
  backgroundImage: `url('${borderHorizontal}')`,
  backgroundRepeat: 'repeat-x',
  position: 'absolute',
  top: -21,
  right: 0,
});

const BorderBottom = styled('div')({
  width: '100%',
  height: '20px',
  backgroundImage: `url('${borderHorizontal}')`,
  backgroundRepeat: 'repeat-x',
  position: 'absolute',
  bottom: 0,
  left: 0,
});

const HorizontalImage = ({ image, borderTop, borderBottom, marginTop, ...props }) => (
  <Wrapper marginTop={marginTop}>
    <Image fluid={image} {...props} />
    {borderTop && <BorderTop />}
    {borderBottom && <BorderBottom />}
  </Wrapper>
)

HorizontalImage.propTypes = {
  image: PropTypes.string.isRequired,
}

export default HorizontalImage
