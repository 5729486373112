import React from "react"
import styled from '@emotion/styled'
import { PropTypes } from "prop-types"
import borderVertical from '../images/borderVertical.svg';
import borderHorizontal from '../images/borderHorizontal.svg';
import { mediaQuery } from "./shared-styles"

const Wrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexBasis: '100%',
  flex: 1,
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  [mediaQuery.medium]: ({
    width: '50%',
  })
});

const Content = styled('div')(props => ({
  width: 'calc(100% - 40px)',
  height: 'calc(100% - 40px)',
  margin: '0 0 0 20px',
  paddingBottom: '20px',
}));

const BorderRight = styled('div')({
  width: '2px',
  height: 'calc(100% + 40px)',
  backgroundImage: `url('${borderVertical}')`,
  backgroundRepeat: 'repeat-y',
  position: 'absolute',
  top: -20,
  right: -21,
  display: 'none',
  [mediaQuery.medium]: ({
    display: 'block',
  })
});

const BorderBottom = styled('div')({
  width: '100%',
  height: '20px',
  backgroundImage: `url('${borderHorizontal}')`,
  backgroundRepeat: 'repeat-x',
  position: 'absolute',
  bottom: -29,
  right: 0,
});

const VerticalText = ({ borderRight, borderBottom, children, ...props }) => (
  <Wrapper>
    <Content {...props}>
      {children}
    </Content>
    {borderRight && <BorderRight />}
    {borderBottom && <BorderBottom />}
  </Wrapper>
)

VerticalText.propTypes = {
  borderRight: PropTypes.bool,
}

export default VerticalText
