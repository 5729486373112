import React from "react"
import styled from '@emotion/styled'

const StyledHeading = styled.h2({
  fontSize: '1.5rem',
  fontWeight: 700,
  textTransform: 'uppercase',
  textAlign: 'center',
  color: 'rgb(32, 32, 32)',
  padding: '20px 0'
})

const Heading = ({ ...props }) => (
  <StyledHeading {...props} />
)

export default Heading
