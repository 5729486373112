import React from "react"
import styled from '@emotion/styled'
import { PropTypes } from "prop-types"
import { mediaQuery } from "./shared-styles"
import Img from "gatsby-image"

import borderVertical from '../images/borderVertical.svg';
import borderHorizontal from '../images/borderHorizontal.svg';

const Wrapper = styled('div')(props => ({
  position: 'relative',
  display: 'flex',
  flexBasis: '100%',
  flexDirection: 'column',
  width: '100%',
  marginTop: props.marginTop? '20px' : '0',
  marginBottom: '20px',
  [mediaQuery.medium]: ({
    width: '50%',
    flex: 1,
  }),
}));

const Image = styled(Img)({
  width: '100%',
  height: 'calc(1050px - 20px)',
  [mediaQuery.medium]: ({
    width: 'calc(100% - 40px)',
    margin: '0 0 0 20px',
  }),
});

const BorderRight = styled('div')({
  width: '2px',
  height: 'calc(100% + 40px)',
  backgroundImage: `url('${borderVertical}')`,
  backgroundRepeat: 'repeat-y',
  position: 'absolute',
  top: -20,
  right: 0,
  display: 'none',
  [mediaQuery.medium]: ({
    display: 'block',
  }),
});

const BorderBottom = styled('div')({
  width: '100%',
  height: '20px',
  backgroundImage: `url('${borderHorizontal}')`,
  backgroundRepeat: 'repeat-x',
  position: 'absolute',
  bottom: -39,
  right: 0,
});

const VerticalImage = ({ image, borderRight, borderBottom, marginTop, ...props }) => (
  <Wrapper marginTop={marginTop}>
    <Image fluid={image} {...props} />
    {borderRight && <BorderRight />}
    {borderBottom && <BorderBottom />}
  </Wrapper>
)

VerticalImage.propTypes = {
  image: PropTypes.string.isRequired
}

export default VerticalImage
