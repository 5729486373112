import React from "react"
import styled from '@emotion/styled'
import { Link } from "gatsby"

import logo from '../images/logo.svg';

const LogoImage = styled('div')({
  width: '30px',
  height: '30px',
  marginTop: '10px',
  backgroundImage: `url(${logo})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'inline-block',
})

const Text = styled('div')({
  display: 'inline-block',
  height: '40px',
  marginTop: '10px',
  verticalAlign: 'top',
  marginLeft: '15px'
})

const StyledLink = styled(Link)({
  padding: '0 15px',
  margin: '0 30px 0 0',
  color: 'black',
  textDecoration: 'none',
  fontWeight: 100,
  opacity: '0.8',
  transition: '.2s background-color ease-in-out',
  '&:hover': {
    backgroundColor: 'rgb(210, 210, 210)',
  }
})

const Logo = ({ ...props }) => (
  <StyledLink {...props}>
    <LogoImage />
    <Text>SvobodaDesign</Text>
  </StyledLink>
)

export default Logo
