import Container from '../components/container'
import Logo from "./logo"
import NavLink from "./nav-link"
import React, { useState } from 'react';
import styled from '@emotion/styled'
import Burger from '@animated-burgers/burger-squeeze';
import { css, Global } from '@emotion/core'
import { mediaQuery } from "./shared-styles"

const StyledHeader = styled('header')({
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  color: 'rgba(0, 0, 0, 0.8)',
  position: 'fixed',
  top: 0,
  zIndex: 1000,
  width: '100%',
  borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
})

const Navigation = styled('nav')({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'flex-start',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '50px',
  position: 'relative',
})

const Navbar = styled('div')(props => ({
  justifyContent: 'center',
  position: 'absolute',
  top: '51px',
  left: '0',
  width: '100%',
  height: props.visible ? 'calc(100vh - 50px)' : 'auto',
  zIndex: '980',
  backgroundColor: 'rgb(54, 54, 54)',
  transition: '.2s height ease-in-out',
  flexDirection: 'column',
  alignItems: 'center',
  display: props.visible ? 'flex' : 'none',
  [mediaQuery.medium]: ({
    position: 'relative',
    top: 'auto',
    left: 'auto',
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  })
}))

const StyledLink = styled(NavLink)({
  width: '100%',
  height: '49px',
  justifyContent: 'center',
  padding: '20px 0',
  borderBottom: '1px solid white',
  color: 'white',
  ':last-child': {
    borderBottom: 'none'
  },
  [mediaQuery.medium]: ({
    padding: '0 15px',
    marginRight: '10px',
    color: 'rgb(60, 60, 60)',
  })
})

const BurgerButton = styled(Burger)({
  width: '34px',
  height: '100%',
  marginLeft: 'auto',
  marginRight: '15px',
  display: 'flex',
  [mediaQuery.medium]: ({
    display: 'none',
  })
})

const globalStyle = css({
  '.burger': {
    height: '3em',
    width: '3em',
    position: 'relative',
    fontSize: '12px',
    cursor: 'pointer',
    transition: 'all .2s',
    WebkitTapHighlightColor: 'transparent',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: '150%',
      width: '150%',
      top: '-25%',
      left: '-25%',
    },
    '.burger-lines': {
      top: '50%',
      marginTop: '-.125em',
      '&, &:after, &:before': {
        pointerEvents: 'none',
        display: 'block',
        content: '""',
        width: '100%',
        borderRadius: '.25em',
        backgroundColor: 'black',
        height: '.25em',
        position: 'absolute',
        WebkitTransform: 'rotate(0)',
        transform: 'rotate(0)',
      },
      '&:after': {
        left: '0',
        top: '-1em',
      },
      '&:before': {
        left: '1em',
        top: '1em',
      },
    },
    '&.burger-squeeze': {
      '.burger-lines': {
        '&, &:after, &:before': {
          transition: 'top .2s .2s, left .1s, transform .2s, background-color .4s .2s',
        },
        '&:after, &:before': {
          width: '2em',
        },
      },
      '&.open .burger-lines': {
        backgroundColor: 'initial',
        '&, &:after, &:before': {
          transition: 'background-color .2s, top .2s, left .2s, transform .2s .15s',
        },
        '&:after, &:before': {
          left: '.5em',
          top: '0',
        },
        '&:before': {
          transform: 'rotate(-45deg)',
        },
        '&:after': {
          transform: 'rotate(45deg)',
        }
      }
    }
  }
})

const Header = () => {
  const [visible, setVisible] = useState(false);

  return (
    <StyledHeader>
      <Global styles={globalStyle}/>
      <Container>
        <Navigation>
          <Logo to="/" />
          <Navbar visible={visible}>
            <StyledLink to="/realizace" onClick={() => setVisible(false)}>Realizace</StyledLink>
            <StyledLink to="/navrhy" onClick={() => setVisible(false)}>Návrhy</StyledLink>
            <StyledLink to="/kontakt" onClick={() => setVisible(false)}>Kontakt</StyledLink>
          </Navbar>
          <BurgerButton isOpen={visible} href="#" onClick={() => setVisible(!visible)} />
        </Navigation>
      </Container>
    </StyledHeader>
  )
}

export default Header
