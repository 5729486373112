import styled from '@emotion/styled'
import { Box } from '@rebass/grid/emotion'

const Container = styled(Box)({
  maxWidth: '1190px'
})

Container.defaultProps = {
  mx: 'auto'
}

export default Container
