import React from 'react';
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import { PropTypes } from "prop-types"
import SEO from "./seo"

import "./layout.css"

const PageWrapper = styled('div')({
  padding: '50px 0 0 0',
  height: 'calc(100% - 50px)',
  display: 'flex',
  flexFlow: 'row wrap',
})

const Layout = ({ title, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <PageWrapper>
      <SEO title={data.site.siteMetadata.title} />
      <Header />
      {children}
    </PageWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
