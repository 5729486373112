export const breakpoints = {
  small: 480,
  medium: 768,
  large: 1024,
  extraLarge: 1200,
};

const breakPointsMap = Object.keys(breakpoints).reduce(
  (acc, label) => {
    const emSize = breakpoints[label] / 16;
    acc[label] = `@media only print, only screen and (min-width: ${emSize}em)`;
    return acc;
  },
  {}
);

export const mediaQuery = {
  ...breakPointsMap,
  print: '@media only print',
};
