import React from "react"
import styled from '@emotion/styled'

const StyledParagraph = styled.p({
  padding: '0',
  color: 'rgb(32, 32, 32)',
  fontSize: '1rem',
  fontWeight: 400,
  textAlign: 'justify',
})

const Text = ({ ...props }) => (
  <StyledParagraph {...props} />
)

export default Text
