import React from "react"
import styled from '@emotion/styled'
import { Link } from "gatsby"

const StyledLink = styled(Link)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textDecoration: 'none',
  fontWeight: 700,
  transition: '.3s background-color ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  }
})

const activeStyle = ({
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
})

const NavLink = ({ ...props }) => (
  <StyledLink {...props} activeStyle={activeStyle} />
)

export default NavLink
